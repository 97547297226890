<template>
     <div class="">
        <label v-if="label" class="app-input-top-label">{{label}}</label>
        <br v-if="additionalInfo" /><small v-if="additionalInfo" class="text-muted">{{additionalInfo}}</small>
        <vue-editor 
                    v-model="cModel" 
                    :id="id"
                    :placeholder="placeholder" 
                    :disabled="disabled" 
                    :editorToolbar="editorToolbar"
                    :ref="'quil'+_uid"
                    @text-change="onTextChanged"
                    @blur="onBlur" 
                    />
    </div>
   
</template>

<script>

import { VueEditor } from "vue2-editor";

export default {
    name: 'AppWysiwyg',

    components: {
        VueEditor
    },


    props: {

        id: { type: String },

        value: { },
        length :{type: Number, default: 0},
        name: { type: String },
        rows: {type: String},
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        label: {type: String},
        additionalInfo: {type: String},
        editorToolbar: {type: Array, default: () => [
                                                    [
                                                        "bold", 
                                                        "italic", 
                                                        "underline", 
                                                    ],
                                                    [   {
                                                            align: ""
                                                        }, 
                                                        {
                                                            align: "center"
                                                        }, 
                                                        {
                                                            align: "right"
                                                        }, 
                                                        {
                                                            align: "justify"
                                                        }
                                                    ], 
                                                    [   {
                                                            list: "ordered"
                                                        }, 
                                                        {
                                                            list: "bullet"
                                                        }, 
                                                    ], 
                                                ]
                            },
                                
    },


    computed: {
        cModel: {
            get() {
                return this.value;
            },
            set(value) {
                if(this.$refs['quil'+this._uid].quill.getText().trim().length > 0){
                    this.$emit('input', value);
                }else{
                    this.$emit('input', '');
                }
            },
        },

        cLength: {
            get() {
                return this.length;
            },
            set(value) {
                this.$emit('update:length', value);
            },
        },
        

    },

    data() {
        return {
           
        }
    },


  methods: {

    
    onTextChanged(event,a,b) { 
        this.cLength = this.$refs['quil'+this._uid].quill.getText().trim().length;
        
      this.$emit('text-change', event);
    },

    onBlur(event) {
      this.$emit('blur', event);
    },


    
  },
}
</script>
<style scoped>

</style>